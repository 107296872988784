import { getServerEndpointUrl } from "./endpoints";

export const postRequest = async (route, requestData) => {
    const url = getServerEndpointUrl(route);

    console.log(url)
    const token = sessionStorage.getItem('infoUser') !== null ? `${sessionStorage.getItem('infoUser')}` : "";

    const queryOptions = {
        method: 'POST',
        headers: { mode: 'no-cors', 'Content-type': 'application/json', 'Jwt': token },
        body: JSON.stringify(requestData)
    };

    const response = await fetch(url, queryOptions);
    return await response.json();
};



export const getRequest = async (route) => {
    const url = getServerEndpointUrl(route);
    const token = sessionStorage.getItem('infoUser') !== null ? `${sessionStorage.getItem('infoUser')}` : "";

    const queryOptions = {
        method: 'GET',
        headers: { mode: 'no-cors', 'Content-type': 'application/json', 'Jwt': token }
    };

    const response = await fetch(url, queryOptions);
    return await response.json();
};



export const putRequest = async (route, requestData) => {
    const url = getServerEndpointUrl(route);
    const token = sessionStorage.getItem('infoUser') !== null ? `${sessionStorage.getItem('infoUser')}` : "";

    const queryOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Jwt': token
        },
        body: JSON.stringify(requestData)
    };

    try {
        const response = await fetch(url, queryOptions);

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Server responded with status: ${response.status}`);
        }
    } catch (error) {
        console.log("PUT DEVELOPMENT REQUEST ERROR => ", error);
        throw error;
    }
};
