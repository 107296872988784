import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import authorImg1 from "../../../assets/images/pole/bachelorette.png";
import authorImg2 from "../../../assets/images/pole/birthday.png";
import WOW from 'wowjs';

const Team = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, []);

  return (
    <section className="team-area ptb-10">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="ch-top-title-s2 text-center">
              <h2 className="site-split-text ch-split-in-up">Stand out with our <small>special</small> Events</h2>
            </div>
          </div>
        </div>
        <div className="team-wrap">
          <div className="row justify-content-center">
            <div className="col col-xl-4 col-lg-6 col-md-6 col-12 d-flex justify-content-center">
              <div className="team-item wow fadeInUp" data-wow-duration="1000ms">
                <div className="team-img">
                  <img src={authorImg1} alt="Bachelorette party" />
                </div>
                <div className="team-text">
                  <h3><Link to="/team-single">Bachelorette party</Link></h3>
                  <p>Celebrate your bachelorette party with an unforgettable pole dance class! Enjoy a fun and empowering experience with your friends. Book now and make lasting memories!</p>
                  <div className="cp-header-area-right">
                    <Link to="/about" className="ch-btn-style-2"><span>Read More</span></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-xl-4 col-lg-6 col-md-6 col-12 d-flex justify-content-center">
              <div className="team-item wow fadeInUp" data-wow-duration="1200ms">
                <div className="team-img">
                  <img src={authorImg2} alt="Birthday" />
                </div>
                <div className="team-text">
                  <h3><Link to="/team-single">Birthday</Link></h3>
                  <p>Make your birthday extraordinary with a unique pole dance class! Gather your friends for a fun and exciting celebration. Reserve your spot today and enjoy a birthday like no other!</p>
                  <div className="cp-header-area-right">
                    <Link to="/about" className="ch-btn-style-2"><span>Read More</span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
