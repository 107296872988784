import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import withness1 from "../../../assets/images/pole/withness1.png";
import withness2 from "../../../assets/images/pole/withness2.png";
import withness3 from "../../../assets/images/pole/withness3.png";
import withness4 from "../../../assets/images/pole/withness4.png";
import authorImg2 from "../../../assets/images/testimonial/shape.png";

import authorImg3 from "../../../assets/images/pole/talentimage.png";

const TestimonialTwo = () => {

    const options = {
      autoplay: true,
      smartSpeed: 2000,
      margin: 5,
      loop: true,
      items: 1,
      autoplayHoverPause: true,
      dots: true,
      navText: ['<i className="ti-arrow-left"></i>', '<i className="ti-arrow-right"></i>'],
      nav: true, 
    };
  
    return (
  
      <section className="testimonial-section-s2 ptb-120">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12">
                      <div className="testimonial-left">
                          <div className="ch-top-title-s3">
                              <span>CLIENTS REVIEWS</span>
                              <h2 className="site-split-text ch-split-in-right">Discover the experiences of our students and instructors</h2>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row align-items-center">
                  <div className="col-lg-7">
                      <OwlCarousel className="testimonial-items testimonial-active owl-carousel" {...options}>
                          <div className="testimonial-item">
                              <div className="testimonial-text">
                                  <ul>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                  </ul>
                                  <p>I've felt great with the instructors, the atmosphere is very comfortable, everyone is very kind, and this experience has been full of love, happiness, and support.
                                  </p>
                                  <div className="testimonial-info">
                                      <div className="testimonial-avatar">
                                          <img src={withness1} alt="" />
                                      </div>
                                      <div className="testimonial-text-btm">
                                          <h3>Daniela Garzon</h3>
                                          <span>Student</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="testimonial-item">
                              <div className="testimonial-text">
                                  <ul>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                  </ul>
                                  <p>I am very happy to belong to the pole dance family; the instructors take care of us and provide all the guidance needed to learn. The facilities are beautiful, and I feel like part of a family and welcomed.
                                  </p>
                                  <div className="testimonial-info">
                                      <div className="testimonial-avatar">
                                          <img src={withness2} alt="" />
                                      </div>
                                      <div className="testimonial-text-btm">
                                          <h3>Alejandra Hernandez</h3>
                                          <span>Student</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="testimonial-item">
                              <div className="testimonial-text">
                                  <ul>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                  </ul>
                                  <p>I have felt excellent, discovering various facets of my physical and mental self, achieving the resilience I have in life. I love pole, and the facilities are easily accessible.
                                  </p>
                                  <div className="testimonial-info">
                                      <div className="testimonial-avatar">
                                          <img src={withness3} alt="" />
                                      </div>
                                      <div className="testimonial-text-btm">
                                          <h3>Vanesa Ortiz</h3>
                                          <span>Student</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="testimonial-item">
                              <div className="testimonial-text">
                                  <ul>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                      <li><i className="ti-star"></i></li>
                                  </ul>
                                  <p>I am very happy and delighted to share with people in the academy who are like family. I'm thrilled when invited to trainings to grow as a teacher in this academy.
                                  </p>
                                  <div className="testimonial-info">
                                      <div className="testimonial-avatar">
                                          <img src={withness4} alt="" />
                                      </div>
                                      <div className="testimonial-text-btm">
                                          <h3>Camila Rodriguez</h3>
                                          <span>Teacher</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </OwlCarousel>
                  </div>
                  <div className="col-lg-5">
                      <div className="testimonial-right-img">
                          <img className="imageParallax" src={authorImg3} alt="" />
                      </div>
                  </div>
              </div>
          </div>
          <div className="right-shape">
              <img src={authorImg2} alt="" />
          </div>
          </section>
    );
  };
  
  export default TestimonialTwo;
  
