import React from 'react';
import '../Services/Modal.css';

const Modal = ({ show, handleClose, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleContentMouseDown = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={showHideClassName} onMouseDown={handleBackdropClick}>
      <section className="modal-main" onMouseDown={handleContentMouseDown}>
        {children}
      </section>

    </div>
  );
};

export default Modal;
