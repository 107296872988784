import { postRequest } from "./base.services";

const sendMail = async(email, name, phone, classes, message) => {
    let params = {
        message,
        email,
        name,
        phone,
        classes
    };

    return await  postRequest("Template/SendMail", params);
};

export default sendMail;
