import React, { useEffect, useState } from 'react';
import { pageTitle } from '../PageTitle';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import companyInfo from '../../assets/data/companyInfo.json'; // Ensure the path is correct
import sendMail from '../../webServices/mailServices';

const Contact = ({ isSection }) => {
  const [info, setInfo] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    note: ''
  });

  if (!isSection) {
    pageTitle('Contact');
  }

  useEffect(() => {
    // Simulate loading from a JSON file
    setInfo(companyInfo);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({
    name: '',
    email: '',
    phone: '',
    subject: '',
    note: ''
    });
    };

  const sendContactEmail = async () => {
    console.log("Sending mail")
    try {
      const response = await sendMail(formData.email, formData.name, formData.phone, formData.subject, formData.note);
console.log(response)
      if (response.status==="success") {
resetForm();
        toast.success('Message sent successfully!');
      } else {
        toast.error('Failed to send message.');
      }
    } catch (error) {
      toast.error('An error occurred while sending the message.');
    }
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
    sendContactEmail();
  };

  return (
    <>
      {!isSection && <HeaderTwo />}
      <ToastContainer />

      <section className="contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-5 col-12">
              <div className="contact-title">
                <div className="ch-top-title-s3">
                  <span>GET IN TOUCH</span>
                  <h2 className="site-split-text ch-split-in-right">Join Our Vibrant Pole Dance Community</h2>
                  <p>Experience the power and grace of pole dance in a supportive and empowering environment. Every class is an opportunity to transform and thrive.</p>
                </div>
              </div>
            </div>
            <div className="col col-lg-7 col-12">
              <div className="contact-form-area">
                <form method="post" onSubmit={SubmitHandler}>
                  <div className="row">
                    <div className="col-md-6 col-12 form-field">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Your Name"
                        autoComplete="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 col-12 form-field">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 col-12 form-field">
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 col-12 form-field">
                      <select name="subject" className="form-control" value={formData.subject} onChange={handleChange}>
                        <option disabled value="">
                          Choose an Option
                        </option>
                        <option>Classes</option>
                        <option>Plans</option>
                        <option>Special Events</option>
                        <option>Other</option>
                      </select>
                    </div>
                    <div className="col-md-12 col-12 form-field">
                      <textarea
                        className="form-control"
                        name="note"
                        id="note"
                        placeholder="Message here.."
                        value={formData.note}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="col-md-12 col-12">
                      <div className="submit-area">
                        <button type="submit" className="ch-btn-style-2">
                          <span>Send</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {!isSection && (
            <div className="office-info ptb-120">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="icon-Group-7044"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Address</h2>
                      <a href={info.gpsLink} target="_blank" rel="noopener noreferrer">
                        {info.address}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="icon-Group-7043"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>E-mail</h2>
                      {info.email && info.email.map((email, index) => (
                        <p key={index}>{email}</p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="icon-Group-7042"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Call us</h2>
                      {info.phone && info.phone.map((phone, index) => (
                        <p key={index}>{phone}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {!isSection && <FooterTwo />}
    </>
  );
};

export default Contact;
