import { Fragment } from "react";
import { Link } from "react-router-dom";
import servicesData from "../../assets/data/services.json"

export const Home = () => (
    <Fragment>
       
      
    </Fragment>
);
export const Services = () => (
    <Fragment>
    <nav>
      <ul>
        {servicesData.map(service => (
          <li key={service.id}><Link to={`/service-single/${service.id}`}>{service.title}</Link></li>
        ))}
      </ul>
    </nav>
  </Fragment>
);
export const Portfolio = () => (
    <Fragment>
        <li><Link to="/portfolio">Portfolio</Link></li>
        <li><Link to="/portfolio-single">Portfolio Single</Link></li>
    </Fragment>
);
export const News = () => (
    <Fragment>
        <li><Link to="/blog-right-sidebar">Blog Right Sidebar</Link></li>
        <li><Link to="/blog-left-sidebar">Blog Left Sidebar</Link></li>
        <li><Link to="/blog-fullwidth">Blog Fullwidth</Link></li>
        <li><Link to="/blog-single">Blog Single</Link></li>
        <li><Link to="/blog-single-left-sidebar">Blog Single Left</Link></li>
        <li><Link to="/blog-single-fullwidth">Blog Single Fullwidth</Link></li>
    </Fragment>
);
