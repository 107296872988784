// Plans.js
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../PageTitle';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import WOW from 'wowjs';

import Modal from '../Payment/Modal';
import box from "../../assets/images/pole/icons/box.png";
import './Modal.css'; 
import { Link } from 'react-router-dom'; 
import Payment from '../Payment/Payment';
import getAllPlans from '../../webServices/plan.service';
import NotificationManager from '../../Utils/NotificationManager';

const Schedule = ({ isSection }) => {
    const [showModal, setShowModal] = useState(false);
    const [plansData,setPlansData]=useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
    });

    if (!isSection) {
        pageTitle('Plans');
    }

    const handlePaymentSuccess = (data) => {

        handleCloseModal();
        NotificationManager.showAlert({
            icon: 'success',
            title: 'Payment Done',
            text: `Check your email for instructions`,
            showCancelButton: false,
            confirmButtonText: 'Close',
          }).then((result) => {
           

          });
        
      };
      
      const handlePaymentFailure = (error) => {

       
        
      };

    useEffect(() => {
       

        const fetchPlans = async () => {
            try {

                const plansServerData = await getAllPlans();

                const transformedData = plansServerData.data.map((plan, index) => ({
                    id: plan.id,
                    title: plan.name,
                    number: plan.price,
                    price: `$${plan.price} USD`,
                    href: "/service-single",
                    time: `${1000 + index * 200}ms`  
                }));

                setPlansData(transformedData);

            } catch (error) {

                console.error('Error fetching plans:', error);

            }
        };

        fetchPlans();
    }, []);

    useEffect(()=>{
        new WOW.WOW({
            live: false
        }).init();
    },[plansData])
    
    useEffect(() => {
        console.log("Open modal ", showModal);
        console.log("Service ", selectedService);
    }, [showModal, selectedService]);

    const handleOpenModal = (service) => {
        document.body.classList.add('no-scroll');
        setSelectedService(service);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        document.body.classList.remove('no-scroll');
        setShowModal(false);
        setSelectedService(null);
        setFormData({ first_name: '',
        last_name: '',
        email: '',
        phone: ''})
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            {!isSection && <HeaderTwo />}
           
            <div className="service-page">
                <section className="service-area ptb-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="ch-top-title-s2 text-center">
                                    <h2 className="site-split-text ch-split-in-up">Schedule your <small>class</small></h2>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="ch-top-title-s2 text-center">
                                    <span>Ready to take control of your schedule? With our new class scheduling module, you can easily browse the current calendar, find classes that match your interests, and join the best fit for you</span>
                                </div>
                                <div style={{ width: '100%', height: '143vh', overflow: 'hidden' }}>
                                <iframe name="frame1" src="https://poledancemiami.punchpass.com/iframe_buttons" height="100" width="100%" frameborder="0"></iframe>
  <iframe
    title="Class Schedule"
    src="https://poledancemiami.punchpass.com/calendar?embed=true"
    style={{ width: '100%', height: '100%', border: 'none' }}
    frameBorder="0"
    allowFullScreen
  ></iframe>
</div>


                            </div>
                        </div>
                        <div className="service-wrap">
                            <div className="row justify-content-center">
                                
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {!isSection && <FooterTwo />}

            <Modal show={showModal} handleClose={handleCloseModal}>
                {selectedService && (
                    <div className="modal-content">
                        <div className="modal-service-details">
                            <h2>{selectedService.title}</h2>
                            <p>{selectedService.description}</p>
                            <p className="price">{selectedService.price}</p>
                        </div>
                        <div className="modal-payment-form">
                        <form className="buyer-form">
    <div className="form-row">
        <div className="form-group">
            <label htmlFor="buyer-first-name">First Name</label>
            <input
                type="text"
                id="buyer-first-name"
                name="first_name"
                pattern="[A-Za-z\s]+"
                title="First name should only contain letters."
                value={formData.first_name}
                onChange={handleChange}
                required
            />
        </div>
        <div className="form-group">
            <label htmlFor="buyer-last-name">Last Name</label>
            <input
                type="text"
                id="buyer-last-name"
                name="last_name"
                pattern="[A-Za-z\s]+"
                title="Last name should only contain letters."
                value={formData.last_name}
                onChange={handleChange}
                required
            />
        </div>
    </div>
    <div className="form-row">
        <div className="form-group">
            <label htmlFor="buyer-phone">Phone Number</label>
            <input
                type="tel"
                id="buyer-phone"
                name="phone"
                pattern="[0-9]{10}"
                title="Phone number should be 10 digits."
                value={formData.phone}
                onChange={handleChange}
                required
            />
        </div>
        <div className="form-group">
            <label htmlFor="buyer-email">Email</label>
            <input
                type="email"
                id="buyer-email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
            />
        </div>
    </div>
</form>
                            <Payment 
                                email={formData.email}
                                first_name={formData.first_name}
                                last_name={formData.last_name}
                                phone={formData.phone}
                                selectedService={selectedService}
                                onPaymentSuccess={handlePaymentSuccess}
                                onPaymentFailure={handlePaymentFailure}
                            />
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Schedule;
