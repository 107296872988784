import React, { useEffect, useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { Link } from "react-router-dom";
import WOW from 'wowjs';
import servicesData from '../../assets/data/services.json';



const Services = ({ isSection }) => {
    if (!isSection) {
        pageTitle('Services');
    }

    const openSchedulePage = () => {
        window.open('https://app.poledance.com.co/user/blocks?location=2', '_blank');
    };

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, []);

    return (
        <>
            {!isSection && <HeaderTwo />}
           
            <div className="service-page">
                
                <section className="service-area ptb-120">
                    <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="ch-top-title-s2">
                            <h2 className="site-split-text ch-split-in-up">Meet our <small>Services</small></h2>
                        </div>
                    </div>
                </div>
                        <div className="service-wrap">
                            <div className="row">
                                {servicesData.map(service => (
                                    <div key={service.id} className="col col-lg-4 col-md-6 col-12">
                                        <div className={`service-item wow fadeInUp`} data-wow-duration={service.time}>
                                            <div className="service-text">
                                                <div className="icon">
                                                    <img style={{ maxWidth: "80px" }} src={service.icon} alt={`${service.title} Icon`} />
                                                </div>
                                                <h3><Link to={service.href+"/"+service.id}>{service.title}</Link></h3>
                                                <p>{service.description}</p>
                                                <div style={{display:'flex',flexDirection:"column"}}>
                                                <Link to={service.href+"/"+service.id} className="read-more">Read More</Link>
                                                <button style={{maxHeight:"50px"}} className='ch-btn-style-2' onClick={openSchedulePage}>Schedule Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {!isSection && <FooterTwo />}
        </>
    );
};

export default Services;
