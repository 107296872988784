import { CreditCard, PaymentForm,ApplePay } from 'react-square-web-payments-sdk';
import paymentStyle from "./payment.css"
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import makePayment from '../../webServices/payment.service';

const Payment = ({ email, first_name, last_name, phone, selectedService, onPaymentSuccess, onPaymentFailure }) => {

  const validateFields = () => {
    let missingFields = [];
    if (!email) missingFields.push('Email');
    if (!first_name) missingFields.push('First name');
    if (!last_name) missingFields.push('Last name');
    if (!phone) missingFields.push('Phone');
    return missingFields;
  };

  
  const getNumericPrice = (price) => {
    return parseFloat(price.replace(/[^0-9.-]+/g, ""));
  };

  const handleCardTokenizeResponseReceived = async (token, buyer) => {

    
    const missingFields = validateFields();
    if (missingFields.length > 0) {
      toast.error(`Please complete the following fields: ${missingFields.join(', ')}.`);
      return; // Stop the payment process if there are missing fields
    }

    try {
      const paymentAmount = getNumericPrice(selectedService.price);
      const responseData = await makePayment(token.token, selectedService.id, email, first_name, last_name, phone, selectedService.title, paymentAmount);
  
      if (responseData.status === "success") {
        toast.success("Payment successful! Please check your email for further details.");
        if (onPaymentSuccess) onPaymentSuccess(responseData);
      } else {
        toast.error("The payment was unsuccessful. Please try again.");
        if (onPaymentFailure) onPaymentFailure(responseData);
      }
    } catch (error) {
      toast.error("An error occurred during the payment process. Please try again.");
      if (onPaymentFailure) onPaymentFailure(error);
    }
  };

  return (
    <div className={paymentStyle.containerSquare}>
      <PaymentForm
        applicationId="sq0idp-rZcnZaniLuPcHiif5tYQqw"
        cardTokenizeResponseReceived={handleCardTokenizeResponseReceived}
        locationId='LR18B8HKPV0PM'
        createPaymentRequest={() => ({
          countryCode: "US",
          currencyCode: "USD",
          total: {
            amount: "1.00",
            label: "Total",
          },
        })}
      >
        <CreditCard />
        <ApplePay />
      </PaymentForm>
    </div>
  );
};

export default Payment;
