import { postRequest } from "./base.services";

const makePayment = async(sourceId,planId,email, first_name, last_name, phone, service,amount) => {
    let params = {
        sourceId,
        email,
        planId,
        first_name,
        last_name,
        phone,
        service,
        amount
    };


    return await  postRequest("Payment/SendPaymentRequest", params);
};

export default makePayment;
