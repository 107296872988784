import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/pole/polebanner.png";
import img2 from "../../../assets/images/pole/flexibilitybanner.png";
import img3 from "../../../assets/images/about/shape-2.png";
import img4 from "../../../assets/images/about/shape-1.png";
import img5 from "../../../assets/images/pole/yogabanner.png";
import WOW from 'wowjs';

const AboutTwo = () => {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (

    <section className="about-area-s2 ptb-120">
        <div className="container">
            <div className="about-wrap">
                <div className="row align-items-center">
                    <div className="col col-lg-6 col-md-12 col-12">
                        <div className="about-left-item  wow fadeInLeftSlow" data-wow-duration="1400ms">
                            <div className="about-left-img">
                                <img src={img1} alt="" />
                                <div className="left-inner-img">
                                    <img src={img2} alt="" />
                                    <div className="left-top-right-shape">
                                        <img src={img3} alt="" />
                                    </div>
                                </div>
                                <div className="left-top-shape">
                                    <img src={img4} alt="" />
                                </div>
                                <div className="exprience-wrap wow fadeInUp" data-wow-duration="1600ms">
                                    <div className="exprience-inner">
                                        <p>13<span>Years Of experience</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="about-right-img">
                                <div className="left-img wow fadeInRightSlow" data-wow-duration="1800ms">
                                    <img src={img5} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-md-12 col-12">
                        <div className="about-right-item wow fadeInRightSlow" data-wow-duration="1400ms">
                            <div className="about-right-text">
                                <span>ABOUT US</span>
                                <h2 className="site-split-text ch-split-in-right">Pole Dance <small>Miami</small> </h2>
                                <p>is a premier academy with over 13 years of experience, serving more than 200 students worldwide. We offer a variety of classes, including pole dance, sexy pole, flexibility, yoga, chair dance, and twerk. Our expert instructors create a fun and empowering environment for all skill levels. Whether you're a beginner or an advanced dancer, join us to experience the transformative power of dance and fitness.</p>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
  );
};

export default AboutTwo;
