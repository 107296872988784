import React, { useEffect, useState } from 'react';
import { pageTitle } from '../PageTitle';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { useParams } from "react-router-dom";
import WOW from 'wowjs';
import img1 from "../../assets/images/service-single/single.jpg";
import servicesData from '../../assets/data/services.json';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Modal from 'react-modal';

const Services = () => {
    const { id } = useParams();
console.log("Received id:", id);  
    const [service, setService] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");

    useEffect(() => {
        pageTitle('Services');
        new WOW.WOW({ live: false }).init();
        const numericId = parseInt(id, 10);
        if (isNaN(numericId)) {
            console.log("Invalid ID");
        } else {
            const foundService = servicesData.find(service => service.id === numericId);
            setService(foundService);
        }

    }, [id]);


useEffect(() => {
    if (service) {
        console.log(service.id);
        console.log(service.gallery);
    }
}, [service]);


const openSchedulePage = () => {
    window.open('https://app.poledance.com.co/user/blocks?location=2', '_blank');
};
    const openModal = (image) => {
        console.log("Opening modal")
        setModalImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalImage("");
    };

    const SubmitHandler = (e) => {
        e.preventDefault();
    }

    if (!service) return <div>Loading...</div>;

    const carouselOptions = {
        items: 3,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        dots: true,
    };

    return (
        <>
            <HeaderTwo />

            <div className="service-single-page ptb-120">
                <div className="container">

                        <div >
                            <div className="serice-details">
                            <div className="details-text">
                                    <h2>{service.title}</h2></div>
                                    <div className="details-img-container">
                            
                            <div className="details-img">
                                <img src={service.portada_image} alt="Portada" />
                            </div>

                            <div className="details-description">
                                <p className="detailText">{service.description}</p>
                                <div className="service-sidebar">
                                
                                <div className="widget info-widget">
                                    <h3>Appointment</h3>
                                    
                                    <button style={{maxHeight:"50px"}}  onClick={openSchedulePage}>Schedule Now</button>
                                </div>
                            </div>
                            </div>
                        </div>
                                
                                <div className="details-text">
                               
                                </div>
                                    <div className="details-items">
                                        <div className="row">
                                            {service.features.map((feature, index) => (
                                                <div className="col-lg-3 col-md-6 col-12" key={index}>
                                                    <h3><i className="ti-check"></i> {feature.feature}</h3>
                                                    <p>{feature.description}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <p>{service.recommendation}</p>
                                </div>

                                <div className="widget gallery-widget">
                                <ReactOwlCarousel key={service.id} {...carouselOptions}>
    {service.gallery.map((image, index) => (
        <div key={index} className="gallery-item">
            <img src={image} onClick={() => openModal(image)} alt={`Gallery image ${index + 1}`} />
        </div>
    ))}
</ReactOwlCarousel>

                                </div>
                                
                            </div>
                        </div>

                </div>
           
            <FooterTwo />
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Image Modal"
                className="image-modal"
                overlayClassName="image-modal-overlay"
                ariaHideApp={false}
            >
                <button onClick={closeModal} className="close-button">Close</button>
                <img src={modalImage} alt="Modal" className="modal-image"/>
            </Modal>
        </>
    );
};

export default Services;
