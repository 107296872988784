import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import AboutOne from '../HomeOne/AboutOne/AboutOne';
import TextAnimationTwo from '../HomeOne/TextAnimationTwo/TextAnimationTwo';
import ServicesTwo from '../HomeTwo/ServicesTwo/ServicesTwo';
import Video from '../HomeTwo/Video/Video';
import Testimonial from '../HomeOne/Testimonial/Testimonial';
import PortfoliosOne from '../HomeOne/PortfoliosOne/PortfoliosOne';
import AboutTwo from '../HomeTwo/AboutTwo/AboutTwo';




const About = () => {
      pageTitle('Special Events');
      
  return (
    
    <>
        <HeaderTwo></HeaderTwo>
        <AboutOne></AboutOne>
        <FooterTwo></FooterTwo>
    </>
  );
};

export default About;