import React, {useEffect} from 'react';
import CountUp from 'react-countup';
import BannerBg from "../../../assets/images/about/arrow-shape.png";
import AboutUs from "../../../assets/images/pole/profile/pole/event2.png";
import img2 from "../../../assets/images/pole/profile/pole/event1.png";
import WOW from 'wowjs';

const About = () => {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (

  <section className="about-area ptb-120">
    <div className="container">
      <div className="about-wrap">
        <div className="row">
          <div className="col col-lg-6 col-md-12 col-12">
            <div className="about-left-item wow fadeInLeftSlow" data-wow-duration="1400ms">
              <div className="about-left-top">
                <div className="left-arrow wow fadeInRightSlow" data-wow-duration="1800ms">
                  <img src={BannerBg} alt="" />
                </div>
                <div className="right-img">
                  <img src={AboutUs} alt="" />
                </div>
                <div className="icon">
                  <i className="icon-Global-connection---Free-networking-icons-2-1"></i>
                </div>
              </div>
              <div className="about-left-bottom">
                <div className="left-img wow fadeInLeftSlow" data-wow-duration="1600ms">
                  <img src={img2} alt="" />
                </div>
                <div className="right-text">
                  <i className="icon-medal"></i>
                  <div className="experience-text-right">
                    <h3><CountUp start={0} end={250} /><span>+</span></h3>
                    <span>Events Hosted</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-lg-6 col-md-12 col-12">
            <div className="about-right-item wow fadeInRightSlow" data-wow-duration="1400ms">
              <div className="about-right-text">
                <span>Special Events</span>
                <h2 className="site-split-text ch-split-in-right">Celebrate with Unforgettable Moments</h2>
                <p>Whether you're planning a bachelorette party or a birthday celebration, we provide a unique and fun-filled experience tailored to your special occasion.</p>
                <div className="about-features-wrap">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="about-features-item wow fadeInUp" data-wow-duration="1600ms">

                        <p>Celebrate the bride-to-be with an unforgettable pole dance party that combines fun, fitness, and fabulous memories.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="about-features-item wow fadeInUp" data-wow-duration="1800ms">

                        <p>Make your birthday extraordinary with a unique and exciting pole dance class that everyone will enjoy.</p>
                      </div>
                    </div>
                    <button style={{maxHeight:"50px"}} className='ch-btn-style-2' >Book Now</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default About;
